import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    minWidth: 400
  }
});

function createData(key, value, note) {
  return { key, value, note };
}
const rows = [
  createData("REQUEST_METHOD", "GET", "HTTP GET方法"),
  createData("REQUEST_URI", "", "RTMP/RTSP直播流的url，参见直播流url格式说明"),
  createData("HTTP_USER_AGENT", "", "User-Agent"),
  createData(
    "HTTP_AUTHORIZATION",
    "",
    "用户的认证信息（rfc2617）仅用于rtsp/http, basic/digest认证"
  ),
  createData("QUERY_STRING", "", "url 查询字符串"),
  createData(
    "X_CGI_PROTOCOL_TYPE",
    "",
    "直播流的协议类型，目前直播推流协议仅支持rtsp/rtmp"
  ),
  createData("X_CGI_STREAM_ID", "", "直播流唯一id"),
  createData("REMOTE_ADDR", "", "推流用户ip"),
  createData("REMOTE_PORT", "", "推流用户端口"),
  createData("X_CGI_AUTH_METHOD", "", "认证方法，publish/ANNOUNCE"),
  createData("X_CGI_EXTEND_METHOD", "X_CGI_METHOD_STREAM_OPEN", "扩展方法")
];
export default function LiveStreamOpen() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        扩展方法：X_CGI_METHOD_STREAM_OPEN, 该方法用于通知app service
        推流直播用户开始向nebula推流的消息， 发生的场景以下两种情况：
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        .当nebula接收到rtmp client 发送的 publish，会触发该方法。
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        .当nebula接收到rtsp client 发送的 ANNOUCE, 会触发该方法。
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        1.FASTCGI/HTTP 参数:
      </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>key</TableCell>
            <TableCell align="left">value</TableCell>
            <TableCell align="left">说明</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            return (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
                <TableCell align="left">{row.note}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Typography variant="subtitle1" gutterBottom>
        2.正文内容: 无。
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        3.返回: http response, 仅状态码，无正文。
      </Typography>
    </>
  );
}
